import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Button,
  Flex,
  List,
  ListItem,
  ListIcon,
  Heading,
  Spinner,
  Input,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { createCheckoutSession } from "../stripe/createCheckoutSession"; // If not used, consider removing
import WidgetWrapper from "./Widgets/WidgetWrapper";
import { getUserCheckoutLink, getUserBillingPortalLink } from "../calls"; // Assuming getUserBillingPortalLink is not used, consider removing
import { useTranslation } from "react-i18next";

const PaySingleCard = ({ child, subscriptionTime, state, userProductID }) => {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(1);

  const features = [
    t("features.payment"),
    t("features.stats"),
    t("features.flex"),
    t("features.analys"),
    t("features.design"),
    t("features.download"),
    t("features.smart"),
  ];

  const quantityList = [1, 5, 10];
  const calculateDiscountPrice = (currentPrice, discountPercentage) => {
    const discountFactor = 1 - discountPercentage / 100;
    const originalPrice = currentPrice / discountFactor;
    return Math.floor(originalPrice - currentPrice);
  };

  const renderPriceDetails = () => {
    const priceData = child.prices?.["each"];
    if (!priceData || !priceData.metadata.discount) return null;

    const priceConvert = priceData.unit_amount / 100;
    const discountPercentage = priceData.metadata.discount;
    const saveAmount = calculateDiscountPrice(priceConvert, discountPercentage);

    return (
      <Flex
        position="absolute"
        right="10px"
        gap="10px"
        top="10px"
        fontSize="12px"
      >
        <BoxDetails label={`${t("save")} ${saveAmount}kr`} />
        <BoxDetails label={`-${discountPercentage}%`} />
      </Flex>
    );
  };

  const BoxDetails = ({ label }) => (
    <Box
      bg="#5138EE"
      color="white"
      fontWeight="bold"
      borderRadius="lg"
      px="5px"
      py="5px"
    >
      {label}
    </Box>
  );

  const calculatePrice = (price, quantity) => {
    return price * (parseInt(quantity) || 1);
  };

  const renderPrice = () => {
    const priceData = child.prices?.["each"];
    if (!priceData || !priceData.unit_amount) return <Box my={3}></Box>;

    const originalPrice = priceData.unit_amount_decimal / 100;

    return (
      <Text my={3} fontSize="32px" fontWeight="bold">
        {calculatePrice(originalPrice, quantity)}
        <span style={{ fontSize: "12px" }}>kr</span>
      </Text>
    );
  };

  const renderFeatures = () =>
    child.features.map((feature, index) => (
      <ListItem key={index} fontSize="14px">
        <ListIcon color="#5138EE" as={CheckCircleIcon} />
        {feature.name}
      </ListItem>
    ));

  const renderActionButton = () => (
    <Flex mt={4} gap={2}>
      {quantityList.map((r) => (
        <Button
          width={"50%"}
          bg={r === quantity ? "#5138EE" : "#C2B9FF"}
          transition={"0.2s"}
          color="white"
          fontWeight="bold"
          onClick={() => setQuantity(r)}
        >
          {r}
        </Button>
      ))}
      <Input
        type="number"
        onChange={(e) => {
          const value = Number(e.target.value);
          // Only update if it's a valid integer within the desired range
          if (Number.isInteger(value)) {
            setQuantity(Math.min(1000, Math.max(1, value)));
          }
        }}
        value={quantity}
        placeholder={t("quantity")}
        min={1}
      />
    </Flex>
  );

  const singlePurchaseHandler = async () => {
    if (child.prices) {
      try {
        const { data } = await getUserCheckoutLink(
          // single purchase checkout link
          () => state.user.getIdToken(),
          {
            success_url: `${window.location.origin}/konto`,
            cancel_url: `${window.location.origin}/konto/betalning`,
            line_items: [
              {
                price: child.prices["each"].id,
                quantity,
              },
            ],
          }
        );
        window.location.href = data.url;
      } catch (error) {
        console.error(error);
      }
    } else {
      // Handle case when there are no prices
    }
  };

  return (
    <Box flex={1} minW="350px" position="relative">
      <WidgetWrapper
        child={
          <Flex flex={1} direction="column">
            {renderPriceDetails()}
            <Heading
              as="h3"
              mt={5}
              fontSize="lg"
              color={child.textColor}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              {child.name}{" "}
              <span style={{ fontSize: "14px" }}>
                ({child.prices["each"].unit_amount / 100}
                {t("shortSEk")} /{t("per")})
              </span>
            </Heading>
            <Text mt={2} fontSize="sm" color={child.textColor}>
              {t("qrCodeDescription")}
            </Text>
            <List my={4} flex={1} role="list" fontSize="sm" spacing={4}>
              {features.map((f, index) => (
                <ListItem key={index} fontSize="14px">
                  <ListIcon color="#5138EE" as={CheckCircleIcon} />
                  {f}
                </ListItem>
              ))}
            </List>
            <Text fontWeight={"bold"} mt={2}>
              {t("amountQR")}
            </Text>
            {renderActionButton()}
            <Flex ml={"auto"}>
              <Button
                mt={2}
                backgroundColor={quantity <= 0 ? "lightgray" : "#5138EE"}
                color={"white"}
                disabled={quantity >= 0}
                onClick={singlePurchaseHandler}
              >
                {t("buy")}
              </Button>
            </Flex>
          </Flex>
        }
      />
    </Box>
  );
};

export default PaySingleCard;

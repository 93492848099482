import {
  Text,
  Flex,
  Grid,
  Button,
  Switch,
  FormControl,
  FormLabel,
  ListItem,
  ListIcon,
  List,
  Heading,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

import { useEffect, useState } from "react";

import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";
import { InputComponent } from "../../../components/Widgets/InputComponent";
import { UserContext } from "../../../context/index.js";
import { useContext } from "react";
import { isPasswordAndEmail } from "../../../functions/isGoogleUser.js";

import { useNavigate } from "react-router-dom";

import { timeToDate } from "../../../functions/timeToDate.js";
import { useTranslation } from "react-i18next";
import { sumRemainingCodes, subscribeToEmail } from "../../../calls.js";

import Payplan from "./Payplan.jsx";
import { getStripeProducts } from "../../../calls";

import { Spinner } from "@chakra-ui/react";

import ScoreWidget from "../../../components/Widgets/ScoreWidget.jsx";
import qrSVG from "../../../content/icons/qr.svg";

const BuyQRCodes = ({ userPlan }) => {
  const { t } = useTranslation();

  const [state] = useContext(UserContext);

  const [products, setProducts] = useState();

  const [totalQR, setTotalQR] = useState(0);
  const [loadingTotalQR, setLoadingTotalQR] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const totalQRData = await sumRemainingCodes(
          () => state.user.getIdToken(),
          process.env.REACT_APP_PRODUCT_ID
        );
        setTotalQR(totalQRData ? totalQRData.data.codes : 0);
        setLoadingTotalQR(false);
      } catch (error) {
        setLoadingTotalQR(false);
      }
    };

    fetchData();
  }, [state]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getStripeProducts(() => state.user.getIdToken());
        setProducts(data.products);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  return (
    <Flex gap={4} flexDirection={["column", "column", "row"]}>
      <Grid gap={4} width={["100%", "100%", "60%"]} marginBottom={"auto"}>
        <WidgetWrapper
          child={<Payplan userPlan={userPlan} products={products} />}
        />
      </Grid>
      <Grid gap={4} width={["100%", "100%", "40%"]} marginBottom={"auto"}>
        <WidgetWrapper
          child={
            !loadingTotalQR ? (
              <ScoreWidget
                text={t("remainingQRCodesToCreate")}
                index={totalQR}
                icon={qrSVG}
              />
            ) : (
              <Flex
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Spinner></Spinner>
              </Flex>
            )
          }
        />
      </Grid>
    </Flex>
  );
};

export default BuyQRCodes;
